import React, { Component } from 'react';
import '../styles/Skills.css';
import FadeInSection from './FadeInSection';

import cpplogo from "../resources/c++-logo.png";
import pythonlogo from "../resources/python-logo.png";
import htmlandcsslogo from "../resources/htmlandcss-logo.png";
import jslogo from "../resources/js-logo.png";

import javalogo from "../resources/java-logo.png";
import sqllogo from "../resources/sql-logo.png";
import linuxlogo from "../resources/linux-logo.png";
import bashlogo from "../resources/bash-logo.png";

import gitlogo from "../resources/git-logo.png";
import atlassianlogo from "../resources/atlassian-logo.png";
import jenkinslogo from "../resources/jenkins-logo.png";
import awslogo from "../resources/aws-logo.png";

import reactlogo from "../resources/react-logo.png";
import nodejslogo from "../resources/nodejs-logo.png";
import clogo from "../resources/c-logo.png";
import kotlinlogo from "../resources/kotlin-logo.png";

const skills = [
    { id: 1,  logo: cpplogo,        name: 'C++' },
    { id: 2,  logo: pythonlogo,     name: 'Python' },
    { id: 3,  logo: htmlandcsslogo, name: 'HTML, CSS' },
    { id: 4,  logo: jslogo,         name: 'Javascript' },
    { id: 5,  logo: javalogo,       name: 'Java' },
    { id: 6,  logo: sqllogo,        name: 'SQL' },
    { id: 7,  logo: linuxlogo,      name: 'Linux' },
    { id: 8,  logo: bashlogo,       name: 'Bash' },
    { id: 9,  logo: gitlogo,        name: 'Git' },
    { id: 10, logo: atlassianlogo,  name: 'Atlassian' },
    { id: 11, logo: jenkinslogo,    name: 'Jenkins' },
    { id: 12, logo: awslogo,        name: 'AWS' },
    { id: 13, logo: reactlogo,      name: 'React' },
    { id: 14, logo: nodejslogo,     name: 'Node' },
    { id: 15, logo: clogo,          name: 'C' },
    { id: 16, logo: kotlinlogo,     name: 'Kotlin' },
];

class Skills extends Component {
    render() {
        return (
            <div id='skills' className='chapter-wrapper text-wrapper'>
                <FadeInSection>
                    <div className='chapter-header header-font mellow-color'>
                        Skills
                    </div>

                    <div className='chapter-content'>
                        I have experience of working in many domains, from full-stack web development to project management and from cyber security
                        to system administration. Here are some of the skills I would associate myself with.
                    </div>
                </FadeInSection>

                <div className="skill-grid">
                    {skills.map(skill => (
                        <FadeInSection key={skill.id} >
                            <div className="skill-cell">
                                <img src={skill.logo} alt=''/>
                                <div className="skill-name">{skill.name}</div>
                            </div>
                        </FadeInSection>
                    ))}
                </div>

            </div>

        );
    }
}

export default Skills;