import "../styles/Ratings.css"

import ratingPicture from '../resources/5-stars.png';

const ratings = [
    { id: 1, pic: ratingPicture, name: 'Björn Isaksson', company: 'Sigma Technology' },
    { id: 2, pic: ratingPicture, name: 'Amir Mustic', company: 'SAAB' },
    { id: 3, pic: ratingPicture, name: 'Mikael Holm', company: 'Knowit' },
    { id: 4, pic: ratingPicture, name: 'Måns Granath', company: 'Ericsson' },
    { id: 5, pic: ratingPicture, name: 'Haze Lidman', company: 'Vertel' },
    { id: 6, pic: ratingPicture, name: 'Tomas Brännström', company: 'Pitch' },
];

const Ratings = () => {
    return (
        <div className="rating-grid">
            {ratings.map(rating => (
                <div key={rating.id} className="rating-cell">
                <img src={rating.pic} className="rating-pic" alt={rating.name} />
                <p>{rating.name}</p>
                <p>{rating.company}</p>
                </div>
            ))}
        </div>
    );
}

export default Ratings