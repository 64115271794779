import "../styles/ContactPage.css"

import React from 'react';
import Navbar from "./Navbar";
import {Link} from "react-router-dom";
import Footer from './Footer';

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
        }
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
        });
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();

        fetch('https://rc0j89v3ok.execute-api.eu-north-1.amazonaws.com/prod/SendContactEmail', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state),
        })
        .then((response) => {
            // Check if the response is successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Parse the JSON response
            return response.json();
        })
        .then((data) => {
            console.log('Success:', data);
            alert("Message Sent.");
            this.resetForm();
        })
        .catch((error) => {
            console.error('Error:', error);
            alert("Message failed to send.");
        });
    }

    render() {
        return(
            <div>
                <Navbar hideNavLinks={true}/>

                <div className="wrapper">
                    <div className="mellow-color header-font contact-form-header">
                        Send me a message!
                    </div>
                    <div className="contact-form">
                        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <div>
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-input" id="name" name="name" value={this.state.name} onChange={this.onNameChange} required />
                            </div>
                            <div>
                                <label htmlFor="email">Email address</label>
                                <input type="email" className="form-input" id="email" name="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange} required />
                            </div>
                            <div>
                                <label htmlFor="message">Message</label>
                                <textarea className="form-input" id="message" name="message" rows="8" value={this.state.message} onChange={this.onMessageChange} required />
                            </div>
                            <Link to="/#contact"><button type="button" className="go-back-btn">Back</button></Link>
                            <button type="submit" className="send-btn">Send</button>
                        </form>
                    </div>
                </div>

                {/* <Footer/> */}
            </div>
        );
    }
  }

  export default ContactPage;