import React, { Component } from 'react';
import FadeInSection from './FadeInSection';
import '../styles/Experience.css';

const experiences = [
    {
        company: "SAAB",
        title: {
            "en": "System Engineer",
            "sv": "Systemingenjör"
        },
        description: {
            "en": "I engineered advanced distributed computer simulations with a focus on assessing functionality, refining pilot training methodologies, and validating novel features in the JAS Gripen fighter jet.",
            "sv": "Jag utvecklade avancerade distribuerade datorsimuleringar med fokus på att bedöma funktionalitet, förbättra pilotutbildningsmetoder och testa nya funktioner i stridsflygplanet JAS Gripen."
        },
    },
    {
        company: "Sordin",
        title: {
            "en": "Embedded Software Engineer",
            "sv": "Inbyggd programvaruingenjör"
        },
        description: {
            "en": "I played a pivotal role in the development of advanced tactical hearing protection. My responsibilities encompassed the design and implementation of embedded software functionalities, including Bluetooth connectivity, sound normalization, and radio integration.",
            "sv": "Jag spelade en avgörande roll i utvecklingen av ett avancerat taktiskt hörselskydd. Mina ansvarsområden omfattade design och implementering av inbyggda programvarufunktioner, inklusive Bluetooth-anslutning, ljudnormalisering och radiointegration."
        },
    },
    {
        company: "Ericsson",
        title: {
            "en": "System Engineer",
            "sv": "Systemingenjör"
        },
        description: {
            "en": "At Ericsson, I contributed to the reliability, resilience, and security of 5G networks, laying the foundation for the future's seamless and secure communication infrastructure. In my role overseeing cybersecurity, I  ensured that the code we delivered met the highest standards of quality and was impervious to potential security breaches.",
            "sv": "På Ericsson utvecklade jag 5G-nätverk. I min roll som ansvarig för cybersäkerhet såg jag även till att koden vi levererade uppfyllde de högsta kvalitetsstandarderna och var motståndskraftig mot potentiella säkerhetsintrång."
        },
    },
    {
        company: "Vertel",
        title: {
            "en": "Full Stack Developer",
            "sv": "Fullstackutvecklare"
        },
        description: {
            "en": "At Vertel, I led the development of a comprehensive suite of business management software called Odoo. Our team's efforts were dedicated to harnessing the full potential of Odoo to create tailored solutions for businesses of all sizes. During my time here, me and a team of around 10 people increased the companys' revenue by well over 300%.",
            "sv": "På Vertel ledde jag utvecklingen av en omfattande svit av affärsstyrningsprogram som heter Odoo. Våra ansträngningar var dedikerade till att utnyttja Odoo:s fulla potential för att skapa skräddarsydda lösningar för företag i alla storlekar, från små start-ups till stora företag."
        },
    },
    {
        company: "Pitch Technologies",
        title: {
            "en": "Java Software Engineer",
            "sv": "Java-programvaruingenjör"
        },
        description: {
            "en": "During my time at Pitch, I developed a distributed simulation system aimed at enhancing military radio communication. I also wrote my thesis \"Implementation of Centralized Multihop Routing with High-Level Architecture: An Empirical Investigation of Context-Specific Heuristics for Efficient Graph Search\"",
            "sv": "Under min tid på Pitch utvecklade jag ett distribuerat simuleringsystem med syfte att förbättra militär radiokommunikation. Jag skrev även min avhandling \"Implementation av centraliserad Multihop Routing med High Level Architecture: En empirisk undersökning av kontextspecifika heuristiker för effektiv grafsökning\""
        },
    },
    // {
    //     company: "Linköping University",
    //     title: {
    //         "en": "Student",
    //         "sv": "Student"
    //     },
    //     description: {
    //         "en": "I pursued a degree in Computer Engineering and had the privilege of serving as a course assistant in a number of programming classes. I was invited to study in Singapore at one of the most prestegious universities in the world.",
    //         "sv": "Jag studerade till Dataingenjör och hade förmånen att tjänstgöra som kursassistent i ett antal programmeringskurser. Jag studerade en termin utomlands i Singapore."
    //     },
    // },
];

class Experience extends Component {
    render() {
        return (
            <div id='experience' className='chapter-wrapper text-wrapper'>
                <FadeInSection>
                    <div className='chapter-header header-font mellow-color'>
                        Experience
                    </div>
                </FadeInSection>

                <div className="experience-list">
                    {experiences.map((experience, index) => (
                        <FadeInSection key={index} >
                        <div className="experience-container">
                            <div className="timeline-container">
                                <div className="timeline-dot"/>
                                <div className="timeline-vertical-line"/>
                            </div>
                            <div className="experience-details">
                                <div className="job-title">{experience.title['en']}</div>
                                <div className="company-name">{experience.company}</div>
                                <div className="description">{experience.description['en']}</div>
                            </div>
                        </div>
                        </FadeInSection>
                    ))}
                </div>
            </div>
        );
    }
}

export default Experience;