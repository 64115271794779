import React, { Component } from 'react';
import ProfilePicture from './ProfilePicture';

import { Link } from 'react-router-dom';
import '../styles/Contact.css';
import FadeInSection from './FadeInSection';

class Contact extends Component {
    render() {
        return (
            <div id='contact' className='chapter-wrapper text-wrapper'>
                <FadeInSection>
                    <div className='chapter-header header-font mellow-color' style={{fontSize:'25px', 'textAlign': 'center'}}>
                        What's next?
                    </div>
                    <ProfilePicture/>
                    <div className='chapter-content'>
                        <div className='contact-header theme-font'>
                            Get In Touch
                        </div>
                        <div className='contact-content'>
                            Whether you're a startup looking to build a minimum viable product or an established company
                            seeking to enhance your existing software, I'm here to help. Let's discuss how I can bring
                            your vision to life!
                        </div>
                        <div style={{textAlign:'center'}}>
                            <Link to='/contact' style={{}}>
                                <button type='buttons'>
                                    Say Hello
                                </button>
                            </Link>
                        </div>
                    </div>
                </FadeInSection>
            </div>
        );
    }
}

export default Contact;