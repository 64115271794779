import "../styles/Navbar.css"
import "../index.css"

import React from 'react';
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Pab from "../resources/pab.png";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideNavLinks : (this.props.hideNavLinks ? "hidden" : ""),
            isMenuOpen: false,
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen,
        }));
    };

    handleLinkClick = () => {
        this.setState({ isMenuOpen: false });
    };

    handleResize = () => {
        if (window.innerWidth > 700) {
            this.setState({ isMenuOpen: false });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { isMenuOpen, hideNavLinks } = this.state;

        return (
            <nav>
                <div className="title">
                    <RouterLink to="/" style={{color:"#eee "}}>
                        <img
                            src={Pab}
                            className="pab"
                            alt="pohlmans_ab">
                        </img>
                    </RouterLink>
                </div>

                {!hideNavLinks && (
                <div className="hamburger-icon" onClick={this.toggleMenu}>
                    <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
                    <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
                    <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
                </div>
                )}

                {!hideNavLinks && (
                    <div className={`pagelinks ${isMenuOpen ? 'open' : ''}`}>
                        <ScrollLink to="about" smooth={true} offset={-80} duration={500} onClick={this.handleLinkClick}>
                            <span>about</span>
                        </ScrollLink>

                        <ScrollLink to="experience" smooth={true} offset={-80} duration={500} onClick={this.handleLinkClick}>
                            <span>work</span>
                        </ScrollLink>

                        <ScrollLink to="skills" smooth={true} offset={-80} duration={500} onClick={this.handleLinkClick}>
                            <span>skills</span>
                        </ScrollLink>

                        <ScrollLink to="contact" smooth={true} offset={-80} duration={500} onClick={this.handleLinkClick}>
                            <span>contact</span>
                        </ScrollLink>
                    </div>
                )}
            </nav>
        );
    }
}

export default Navbar