import "../styles/Footer.css"

const Footer = () => {
    return (
        <footer className="footer-text">
            Designed and built by Lukas Pohlman 2024
        </footer>
    );
}

export default Footer