import React from "react";
import Helmet from "react-helmet";
import Home from "./components/HomePage";
import ContactPage from "./components/ContactPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
    return (
        <Router>
            <Helmet>
                <title>Pohlmans</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
            </Routes>
        </Router>
    )
}

export default App