import React, { useState, useRef, useEffect } from 'react';
import "../styles/FadeInSection.css";

function FadeInSection(props) {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef();

    const getThreshold = () => {
        return window.innerWidth <= 750 ? [0.2, 0.8] : [0.2, 0.6];
    };

    const getRootMargin = () => {
        return window.innerWidth <= 750 ? '0px' : '-100px 0px';
    };

    useEffect(() => {
        const currentElement = domRef.current;
        if (!currentElement) return;

        let observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => setVisible(entry.isIntersecting));
            },
            {
                threshold: getThreshold(),
                rootMargin: getRootMargin()
            }
        );

        observer.observe(currentElement);

        // Handler to update observer on resize
        const handleResize = () => {
            // Disconnect the current observer
            observer.disconnect();

            // Create a new observer with updated options
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => setVisible(entry.isIntersecting));
                },
                {
                    threshold: getThreshold(),
                    rootMargin: getRootMargin()
                }
            );

            // Re-observe the element with the new observer
            observer.observe(currentElement);
        };

        // Throttle the resize handler to improve performance
        let resizeTimeout;
        const throttledResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(handleResize, 300);
        };

        window.addEventListener('resize', throttledResize);

        return () => {
            window.removeEventListener('resize', throttledResize);
            if (observer && currentElement) {
                observer.unobserve(currentElement);
                observer.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this runs once

    return (
        <div
            className={`fade-in-out-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}>
            {props.children}
        </div>
    );
}

export default FadeInSection;