import '../styles/HomePage.css'
import React from 'react';
import Navbar from './Navbar';
import Introduction from './Introduction';
import Experience from './Experience';
import Ratings from './Ratings';
import Skills from './Skills';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';

class Home extends React.Component {
    componentDidMount() {
        const myId = window.location.hash.slice(1);
        if (myId) {
            const elem = document.getElementById(myId);
            var elementPosition = elem.getBoundingClientRect().top;
            window.scrollTo({
                top: elementPosition - 300
            });
        }
    }

    render() {
        return (
            <div>
                <div className='background-image'/>

                <Navbar/>

                <Introduction/>

                <About/>

                <Experience/>

                <Skills/>

                <Contact/>

                <Ratings/>

                <Footer/>
            </div>
        );
    }
}

export default Home