import React, { Component } from 'react';
import '../styles/Introduction.css';
import FadeInSection from './FadeInSection';
import ArrowDown from "../resources/arrow-down.png";
import { Link as ScrollLink } from "react-scroll";

class Introduction extends Component {
    render() {
        return (
            <FadeInSection>
                <div className='introduction'>
                    <div className='name intro-font'>
                        I build cutting-edge software for companies that require excellence.
                    </div>
                </div>
                <ScrollLink to="about" smooth={true} offset={-80} duration={500}>
                    <img src={ArrowDown} className="arrow-down" alt="arrow-down"/>
                </ScrollLink>
            </FadeInSection>
        );
    }
}

export default Introduction;