import "../styles/ProfilePicture.css"

import Logo from "../resources/lukas.jpg";

const ProfilePicture = () => {
    return (
        <img
            src={Logo}
            className="profile_picture"
            alt="profile_picture"/>
    );
}

export default ProfilePicture